import React from "react"
import styled from "styled-components"

const Input = styled.input`
  position: relative;
  appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: #fff;
  border: 1px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 #fff;
  transition-duration: 200ms;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 26px;
    height: 26px;
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  }

  &:checked {
    border-color: #4ed164;
    box-shadow: inset 20px 0 0 0 #4ed164;

    &:after {
      left: 20px;
      box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
    }

    &:disabled {
      border-color: #d9dadc;
      box-shadow: inset 20px 0 0 0 #d9dadc;
    }
  }

  &:disabled {
    cursor: default;
  }
`

interface SwitchProps {
  isChecked?: boolean
  label: string
  isDisabled?: boolean
  name: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const Switch: React.FunctionComponent<SwitchProps> = ({ isChecked, isDisabled, label, name, onChange }) => {
  return (
    <Input
      type="checkbox"
      aria-label={label}
      defaultChecked={isChecked}
      disabled={isDisabled}
      name={name}
      onChange={onChange}
    ></Input>
  )
}

Switch.defaultProps = {
  isChecked: false,
  isDisabled: false,
} as Partial<SwitchProps>

export default Switch
