import React from "react"
import styled from "styled-components"

import devices from "../../helpers/devices"
import { Button } from "../simpa-design-kit"

const StyledBanner = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;

  background-color: #2b6fc3;
  color: #fff;

  padding: 1rem;

  span {
    margin-bottom: 1rem;
    vertical-align: middle;

    a {
      color: #fff;
      text-decoration: underline;
      text-transform: lowercase;
    }
  }

  div {
    flex-shrink: 0;

    button:last-child {
      margin-right: 0;
    }
  }

  @media ${devices.desktop} {
    flex-direction: row;

    span {
      margin: 0 1rem 0 0;
    }
  }
`

type BannerProps = {
  l10n: SimpaConsentSolutionTypes.L10N
  onAcceptAllClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  onRejectAllClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  onCustomizeClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  readMore?: JSX.Element
}

export const Banner = ({ l10n, onAcceptAllClick, onRejectAllClick, onCustomizeClick, readMore }: BannerProps) => (
  <StyledBanner>
    <span>
      {l10n.message} {readMore}
    </span>
    <div>
      <Button aria-label={l10n.btnAcceptAll} className="primary small" onClick={onAcceptAllClick}>
        {l10n.btnAcceptAll}
      </Button>
      <Button aria-label={l10n.btnRejectAll} className="small" onClick={onRejectAllClick}>
        {l10n.btnRejectAll}
      </Button>
      <Button aria-label={l10n?.btnCustomize} className="small" onClick={onCustomizeClick}>
        {l10n?.btnCustomize}
      </Button>
    </div>
  </StyledBanner>
)

export default Banner
