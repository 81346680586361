import React from "react"
import styled from "styled-components"

import Switch from "../atoms/Switch"
import { Button } from "../simpa-design-kit"

import { CAT_NECESSARY } from "./constants"

const StyledOptionsModal = styled.div`
  .button-group-right {
    width: 100%;
    text-align: right;
  }
  .option {
    .title.is-5 {
      margin-bottom: 8px;
    }
    &:not(:last-child) {
      margin-bottom: 16px;
      padding-bottom: 8px;
      border-bottom: 1px solid #ddd;
    }
    .description {
      width: 100%;
      padding-right: 5px;
      font-size: 0.975rem;
    }
  }
`

/**
 * Get options modal options (sorry for this naming).
 * @param  {SimpaConsentSolutionTypes.L10N}                 l10n    The L10N.
 * @param  {SimpaConsentSolutionTypes.Consent}              consent The consent.
 * @return {SimpaConsentSolutionTypes.OptionsModalOption[]}         The options modal options.
 */
function getOptionsModalOptions(
  l10n: SimpaConsentSolutionTypes.L10N,
  consent: SimpaConsentSolutionTypes.Consent
): SimpaConsentSolutionTypes.OptionsModalOption[] {
  return [
    {
      desc: l10n[`${CAT_NECESSARY}Desc`],
      isChecked: true,
      isDisabled: true,
      key: CAT_NECESSARY,
      name: l10n[`${CAT_NECESSARY}Name`],
    },
    ...Object.keys(consent).map(categoryName => ({
      desc: (l10n as any)[`${categoryName}Desc`],
      isChecked: consent[categoryName].hasConsent,
      isDisabled: false,
      key: categoryName,
      name: (l10n as any)[`${categoryName}Name`],
    })),
  ]
}

interface OptionsModalProps {
  consent: SimpaConsentSolutionTypes.Consent
  l10n: SimpaConsentSolutionTypes.L10N
  onAcceptClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  onCloseClick: (event: React.MouseEvent<Element, MouseEvent>) => void
  onOptionChanged: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const OptionsModal = ({ consent, l10n, onAcceptClick, onCloseClick, onOptionChanged }: OptionsModalProps) => (
  <StyledOptionsModal className="modal is-active">
    <div className="modal-background" onClick={onCloseClick}></div>
    <div className="modal-card">
      <header className="modal-card-head">
        <p className="modal-card-title">{l10n?.modalTitle}</p>
        <button className="delete" aria-label="close" onClick={onCloseClick}></button>
      </header>
      <section className="modal-card-body">
        {getOptionsModalOptions(l10n, consent).map(option => (
          <div className="option" key={option.key}>
            <table>
              <tbody>
                <tr>
                  <th colSpan={2}>
                    <h5 className="title is-5">{option.name}</h5>
                  </th>
                </tr>
                <tr>
                  <td className="description">
                    <span>{option.desc}</span>
                  </td>
                  <td>
                    <Switch
                      isChecked={option.isChecked}
                      isDisabled={option.isDisabled}
                      label={option.name}
                      name={option.key}
                      onChange={option.isDisabled ? () => {} : onOptionChanged}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ))}
      </section>
      <footer className="modal-card-foot">
        <div className="button-group-right">
          <Button className="small" onClick={onCloseClick}>
            {l10n.btnCancel}
          </Button>
          <Button className="primary small" onClick={onAcceptClick}>
            {l10n.btnAccept}
          </Button>
        </div>
      </footer>
    </div>
  </StyledOptionsModal>
)

export default OptionsModal
