import React from "react"
import styled from "styled-components"
import { Link, useIntl } from "gatsby-plugin-intl"

import LinkTarget from "./atoms/LinkTarget"
import devices from "../helpers/devices"
import { Button } from "./simpa-design-kit"
import { EVENT_SHOW_OPTIONS_MODAL } from "../components/simpa-consent-solution/constants"

interface FooterProps {
  className?: string
  isLandingPage?: boolean
}

const Component = styled.footer`
  position: relative;
  z-index: 0;

  margin-top: ${(props: FooterProps) => (props.isLandingPage ? "0" : "7rem")};

  .get-together {
    height: 200px;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(-100px);
    z-index: 1;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    margin: 0 auto;
    padding: 0 1.5rem;
    width: 320px;

    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0.5em 1em -0.125em rgba(51, 51, 51, 0.1), 0 0px 0 1px rgba(51, 51, 51, 0.02);

    @media ${devices.tablet} {
      width: 533px;
    }

    .title {
      color: var(--primary-color);
      text-align: center;
    }
  }

  .section {
    padding: ${(props: FooterProps) => (props.isLandingPage ? "3rem" : "9rem")} 1.5rem 3rem;

    background-color: #edf7f0;

    h4 {
      margin-bottom: 0.5rem;

      color: var(--grey-1-color);
      font-size: 1.5rem;
      font-weight: 600;

      &.simpa {
        color: var(--primary-color);
      }
    }

    ul {
      margin: 0 0 1.5rem 0;

      list-style: none;
    }

    .copyright {
      color: var(--grey-1-color);
      text-align: center;
    }
  }
`

const Footer = ({ className, isLandingPage }: FooterProps) => {
  const intl = useIntl()

  const getTarget = () => (isLandingPage ? "_blank" : "_self")

  function onSayHelloClick() {
    if (typeof window !== "undefined" && typeof window.open !== "undefined") {
      const href = intl.formatMessage({ id: "href_say_hello_form" })
      window.open(href)
    }
  }

  function openCookieSettings() {
    document.dispatchEvent(new Event(EVENT_SHOW_OPTIONS_MODAL))
  }

  return (
    <Component className={className} isLandingPage={isLandingPage}>
      {/* {!isLandingPage ? (
        <div className="get-together">
          <h3 className="title is-3">{intl.formatMessage({ id: "It is time to get it together." })}</h3>
          <Button className="primary" onClick={onSayHelloClick}>
            {intl.formatMessage({ id: "Say hello!" })}
          </Button>
        </div>
      ) : (
        undefined
      )} */}
      <div className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-one-fifth">
              <Link title={intl.formatMessage({ id: "Home" })} to="/">
                <h4 className="title simpa">Simpa.</h4>
              </Link>
            </div>
            <div className="column is-one-fifth">
              <h4 className="title">{intl.formatMessage({ id: "Company" })}</h4>
              <ul>
                <li>
                  <LinkTarget
                    aria-label={intl.formatMessage({ id: "About us" })}
                    target={getTarget()}
                    title={intl.formatMessage({ id: "About us" })}
                    to="/about/"
                  >
                    {intl.formatMessage({ id: "About us" })}
                  </LinkTarget>
                </li>
                <li>
                  <LinkTarget
                    aria-label={intl.formatMessage({ id: "Blog" })}
                    target={getTarget()}
                    title={intl.formatMessage({ id: "Blog" })}
                    to="/blog/"
                  >
                    {intl.formatMessage({ id: "Blog" })}
                  </LinkTarget>
                </li>
                <li>
                  <LinkTarget
                    aria-label={intl.formatMessage({ id: "Contacts" })}
                    target={getTarget()}
                    title={intl.formatMessage({ id: "Contacts" })}
                    to="/contacts/"
                  >
                    {intl.formatMessage({ id: "Contacts" })}
                  </LinkTarget>
                </li>
              </ul>
            </div>
            <div className="column is-one-fifth">
              <h4 className="title">{intl.formatMessage({ id: "Products" })}</h4>
              <ul>
                <li>
                  <LinkTarget
                    aria-label="Cocktail Zone"
                    target={getTarget()}
                    title="Cocktail Zone"
                    to="/products/cocktail-zone/"
                  >
                    Cocktail Zone
                  </LinkTarget>
                </li>
              </ul>
            </div>
            <div className="column is-one-fifth">
              <h4 className="title">{intl.formatMessage({ id: "Social" })}</h4>
              <ul>
                <li>
                  <a href="https://www.facebook.com/Simpadot/" rel="noreferrer" target="_blank" title="Facebook">
                    Facebook
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/Simpadot/" rel="noreferrer" target="_blank" title="Instagram">
                    Instagram
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/Simpadot/"
                    rel="noreferrer"
                    target="_blank"
                    title="LinkedIn"
                  >
                    LinkedIn
                  </a>
                </li>
              </ul>
            </div>
            <div className="column is-one-fifth">
              <h4 className="title">{intl.formatMessage({ id: "Terms & Conditions" })}</h4>
              <ul>
                <li>
                  <LinkTarget
                    aria-label={intl.formatMessage({ id: "Cookie Policy" })}
                    target={getTarget()}
                    title={intl.formatMessage({ id: "Cookie Policy" })}
                    to="/cookie-policy/"
                  >
                    {intl.formatMessage({ id: "Cookie Policy" })}
                  </LinkTarget>
                </li>
                <li>
                  <a
                    aria-label={intl.formatMessage({ id: "Cookie Settings" })}
                    onClick={openCookieSettings}
                    title={intl.formatMessage({ id: "Cookie Settings" })}
                  >
                    {intl.formatMessage({ id: "Cookie Settings" })}
                  </a>
                </li>
                <li>
                  <a
                    aria-label={intl.formatMessage({ id: "Privacy Policy" })}
                    href="https://www.iubenda.com/privacy-policy/87271153"
                    target={getTarget()}
                    title={intl.formatMessage({ id: "Privacy Policy" })}
                  >
                    {intl.formatMessage({ id: "Privacy Policy" })}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <p className="copyright">© 2020-{new Date().getFullYear()} Simpa.</p>
            </div>
          </div>
        </div>
      </div>
    </Component>
  )
}

Footer.defaultProps = {
  isLandingPage: false,
} as Partial<FooterProps>

export default Footer
