import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import Helmet from "react-helmet"

function getTitleFromTemplate(brand: string, title: string): string {
  return `${title} | ${brand}`
}

interface MetaProps {
  description?: string
  image?: {
    height: number
    src: string
    width: number
  }
  intl: any
  meta?: (
    | {
        name: string
        content: any
        property?: undefined
      }
    | {
        property: string
        content: any
        name?: undefined
      }
  )[]
  pathname: string
  title: string
  useTitleTemplate?: boolean
}

const Meta: React.FunctionComponent<MetaProps> = ({
  description,
  image,
  intl,
  meta,
  pathname,
  title,
  useTitleTemplate,
}) => {
  const {
    site: {
      siteMetadata: { author, siteUrl, title: brand },
    },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            author
            siteUrl
            title
          }
        }
      }
    `
  )

  const canonicalUrl = `${siteUrl}${pathname}`
  const metaDescription = description || intl.formatMessage({ id: "meta_description" })
  const metaImageHeight = image ? image.height : 1200
  const metaImageSrc = image ? image.src : `${siteUrl}/og_image.png`
  const metaImageWidth = image ? image.width : 1200
  const metaTitle = useTitleTemplate ? getTitleFromTemplate(brand, title) : title

  const defaultMeta = [
    { name: "description", content: metaDescription },
    { name: "google-site-verification", content: "6ZTTIVCmuF4ZvZz9_keKUIxZMaeMoUn5a1X_tgQijN4" },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:creator", content: author },
    { name: "twitter:description", content: metaDescription },
    { name: "twitter:image", content: metaImageSrc },
    { name: "twitter:site", content: canonicalUrl },
    { name: "twitter:title", content: metaTitle },
    { property: "og:description", content: metaDescription },
    { property: "og:image", content: metaImageSrc },
    { property: "og:image:height", content: metaImageHeight },
    { property: "og:image:width", content: metaImageWidth },
    { property: "og:locale", content: intl.locale },
    { property: "og:title", content: metaTitle },
    { property: "og:type", content: "website" },
    { property: "og:url", content: canonicalUrl },
  ]

  return (
    <>
      <Helmet
        defer={false}
        htmlAttributes={{ lang: intl.locale }}
        link={[{ href: canonicalUrl, rel: "canonical" }]}
        title={metaTitle}
        meta={meta ? defaultMeta.concat(meta) : defaultMeta}
      />
      <Helmet>
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "name": "Simpa.",
            "url": "https://simpa.maxdelia.it/",
            "logo": "https://simpa.maxdelia.it/og_image.png",
            "sameAs": [
              "https://www.facebook.com/Simpadot/",
              "https://www.instagram.com/Simpadot/",
              "https://www.linkedin.com/company/Simpadot/"
            ]
          }`}
        </script>
      </Helmet>
    </>
  )
}

Meta.defaultProps = {
  meta: [],
  useTitleTemplate: true,
}

export default injectIntl(Meta)
