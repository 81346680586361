export const cookies: { [key: string]: any } = {
  necessary: {
    simpaConsentSolution: {
      cookies: [
        "simpa_consent_solution_marketing_cookies_enabled",
        "simpa_consent_solution_preferences_cookies_enabled",
        "simpa_consent_solution_statistics_cookies_enabled",
        "simpa_consent_solution_updated_at",
      ],
      expiresAfter: "1 year",
      mustWipe: false,
      name: "Simpa Consent Solution",
      showInPolicy: true,
    },
  },
  statistics: {
    fullstory: {
      cookies: ["fs_uid"],
      expiresAfter: "1 year",
      mustWipe: false,
      name: "Fullstory",
      showInPolicy: false,
    },
    googleAnalytics: {
      cookies: [
        "__utma",
        "__utmb",
        "__utmc",
        "__utmx",
        "__utmxx",
        "__utmz",
        "_dc_gtm_UA-154443187-1",
        "_ga",
        "_ga_GX7FBEZ0B8",
        "_gat_UA-154443187-1",
        "_gat_gtag_UA_154443187_1",
        "_gid",
      ],
      expiresAfter: "2 years",
      mustWipe: true,
      name: "Google Analytics",
      showInPolicy: true,
    },
  },
  marketing: {
    facebook: {
      cookies: ["_fbp"],
      expiresAfter: "1 year",
      mustWipe: false,
      name: "Facebook",
      showInPolicy: false,
    },
    googleAds: {
      cookies: ["_gcl_au"],
      expiresAfter: "1 year",
      mustWipe: false,
      name: "Google Ads",
      showInPolicy: false,
    },
  },
}

export const listCookiesToWipe = (categoryId: string): string[] =>
  Object.keys(cookies[categoryId]).reduce((acc: string[], serviceId: string) => {
    if (!cookies[categoryId][serviceId].mustWipe) return acc
    return [...acc, ...cookies[categoryId][serviceId].cookies]
  }, [])

export default cookies
