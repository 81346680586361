import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import Helmet from "react-helmet"

const Favicon: React.FunctionComponent = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )

  return (
    <Helmet
      link={[
        { rel: "apple-touch-icon", sizes: "180x180", href: "/apple-touch-icon.png" },
        { rel: "icon", type: "image/png", sizes: "32x32", href: "/favicon-32x32.png" },
        { rel: "icon", type: "image/png", sizes: "16x16", href: "/favicon-16x16.png" },
        { rel: "manifest", href: "/site.webmanifest" },
        { rel: "mask-icon", href: "/safari-pinned-tab.svg", color: "#32529c" },
      ]}
      meta={[
        { name: "apple-mobile-web-app-title", content: `${site.siteMetadata.title}` },
        { name: "application-name", content: `${site.siteMetadata.title}` },
        { name: "msapplication-TileColor", content: "#32529c" },
        { name: "theme-color", content: "#ffffff" },
      ]}
    />
  )
}

export default Favicon
