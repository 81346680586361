import Cookies from "js-cookie"
import React, { useEffect, useState } from "react"

import Banner from "./Banner"
import OptionsModal from "./OptionsModal"
import { CATEGORIES, COOKIE_UPDATED_AT, EVENT_SHOW_OPTIONS_MODAL, VENDOR_PREFIX } from "./constants"
import { deepEqual } from "./utils"

const defaultL10N: SimpaConsentSolutionTypes.L10N = {
  message:
    "We use cookies to provide you a better user experience, analyzing our website traffic and usage. By clicking Accept you consent to the use of cookies in accordance with our",
  btnAcceptAll: "Accept All",
  btnRejectAll: "Reject All",
  btnCustomize: "Customize",
  modalTitle: "Consent preferences",
  btnAccept: "Accept",
  btnCancel: "Cancel",
  marketingDesc:
    "These cookies or other identifiers enable us to deliver personalized marketing content to you based on your behavior and to operate, serve and track ads.",
  marketingName: "Marketing",
  necessaryDesc:
    "These cookies or other identifiers are required for allowing our service working and, therefore, do not require you to consent.",
  necessaryName: "Strictly necessary",
  preferencesDesc:
    "These cookies or other identifiers allow us to keep record of your preferences related to the features of our service.",
  preferencesName: "Preferences",
  statisticsDesc:
    "These cookies or other identifiers allow us to measure traffic and analyze your behavior with the goal of improving our service.",
  statisticsName: "Statistics",
}

/**
 * Remove a cookie in both domain and its subdomains.
 * @param  {string} cookieName The cookie name.
 */
function safeRemoveCookie(cookieName: string) {
  let domain: string = ""
  if (typeof window !== "undefined" && typeof window.location !== "undefined") {
    domain = window.location.hostname
  }
  if (domain) {
    Cookies.remove(cookieName, { domain: `.${domain}`, path: "/" })
  }
  Cookies.remove(cookieName, { path: "/" })
}

/**
 * Clean all the cookies it can access.
 */
function cleanAllCookies() {
  if (typeof document !== "undefined" && typeof document.cookie !== "undefined") {
    const allCookies = document.cookie.split(";").map(s => s.split("=")[0].trim())
    allCookies.forEach(cookieName => safeRemoveCookie(cookieName))
  }
}

/**
 * Clean local cookies, based on provided consent.
 * @param  {SimpaConsentSolutionTypes.Consent} consent       The consent to apply.
 * @param  {Record<string, string[]>}          [userCookies] The optional user defined cookies.
 */
function cleanCookiesByOptions(consent: SimpaConsentSolutionTypes.Consent, userCookies?: Record<string, string[]>) {
  if (!userCookies) return // I have nothing to do.
  Object.keys(consent).forEach(categoryName => {
    if (!consent[categoryName].hasConsent && userCookies[categoryName] && Array.isArray(userCookies[categoryName])) {
      // No consent for this cookie category, so remove each user defined cookie.
      userCookies[categoryName].forEach(cookieName => safeRemoveCookie(cookieName))
    }
  })
}

/**
 * Get cookie name for category.
 * @param  {string} categoryName The category name.
 * @return {string}              The cookie name.
 */
function getCookieNameForCategory(categoryName: string): string {
  return `${VENDOR_PREFIX}_${categoryName}_cookies_enabled`
}

/**
 * Tell if a cookie category has been consented.
 * @param  {string}  categoryName The category name.
 * @return {boolean}              True if the cookie category has been consented.
 */
function hasConsent(categoryName: string): boolean {
  const cookieName = getCookieNameForCategory(categoryName)
  const cookie = Cookies.get(cookieName)
  return !!cookie && cookie === "true"
}

/**
 * Get local consent, reading user's cookies.
 * @return {SimpaConsentSolutionTypes.Consent} The local consent.
 */
function getLocalConsent(): SimpaConsentSolutionTypes.Consent {
  return CATEGORIES.reduce((acc: SimpaConsentSolutionTypes.Consent, categoryName: string) => {
    acc[categoryName] = {
      hasBeenRevoked: false,
      hasConsent: hasConsent(categoryName),
    }
    return acc
  }, {})
}

/**
 * Set local consent, writing user's cookies.
 * @param  {Consent} consent The consent to persist.
 */
function setLocalConsent(consent: SimpaConsentSolutionTypes.Consent) {
  const oneYearFromNow = new Date()
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1)
  const cookieOptions = { expires: oneYearFromNow, path: "/" }
  Object.keys(consent).forEach(categoryName => {
    const cookieName = getCookieNameForCategory(categoryName)
    if (consent[categoryName].hasConsent) {
      Cookies.set(cookieName, "true", cookieOptions)
    } else {
      Cookies.remove(cookieName, { path: "/" })
    }
  })
  Cookies.set(COOKIE_UPDATED_AT, new Date().toISOString(), cookieOptions)
}

interface SimpaConsentSolutionProps {
  cookies?: { [key: string]: string[] }
  l10n?: SimpaConsentSolutionTypes.L10N
  onConsentChanged?: Function
  readMore?: any
}

export const SimpaConsentSolution = ({
  cookies,
  l10n = defaultL10N,
  onConsentChanged,
  readMore,
}: SimpaConsentSolutionProps) => {
  const [isOptionsModalVisible, setIsOptionsModalVisible] = useState(false)

  // 1. Understand if banner should be displayed.
  const isBannerVisible = !Cookies.get(COOKIE_UPDATED_AT)

  // 2. If no consent has ever been expressed, SHUT. DOWN. EVERYTHING.
  if (isBannerVisible) cleanAllCookies()

  // 3. Get local consent.
  const localConsent: SimpaConsentSolutionTypes.Consent = getLocalConsent()

  // 4. Put it into component state.
  const [consent, setConsent] = useState<SimpaConsentSolutionTypes.Consent>(localConsent)

  // 5. Eventually perform some cleanup based on consent.
  cleanCookiesByOptions(consent, cookies)

  function applyConsent(consentoToApply: SimpaConsentSolutionTypes.Consent) {
    const currentLocalConsent = getLocalConsent()
    const somethingChanged = !deepEqual(consentoToApply, currentLocalConsent)
    if (isBannerVisible || somethingChanged) {
      setLocalConsent(consentoToApply)
      setConsent(consentoToApply)
      if (onConsentChanged) onConsentChanged(consentoToApply)
    }
    closeOptionsModal()
  }

  function closeOptionsModal() {
    if (isOptionsModalVisible) setIsOptionsModalVisible(false)
  }

  function openOptionsModal() {
    setConsent(getLocalConsent()) // FIXME Force a state update before opening options modal, I don't like it :(
    if (!isOptionsModalVisible) setIsOptionsModalVisible(true)
  }

  const getAllOptions = (hasConsent: boolean): SimpaConsentSolutionTypes.Consent =>
    CATEGORIES.reduce((acc: SimpaConsentSolutionTypes.Consent, categoryName: string) => {
      acc[categoryName] = { hasBeenRevoked: false, hasConsent }
      return acc
    }, {})

  function onOptionChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const categoryName = event.target.name
    const prevValue = localConsent[categoryName].hasConsent
    consent[categoryName] = {
      hasBeenRevoked: prevValue && !event.target.checked,
      hasConsent: event.target.checked,
    }
  }

  useEffect(() => {
    document.addEventListener(EVENT_SHOW_OPTIONS_MODAL, openOptionsModal)
    if (onConsentChanged) onConsentChanged(consent)

    return () => {
      document.removeEventListener(EVENT_SHOW_OPTIONS_MODAL, openOptionsModal)
    }
  }, [])

  return (
    <>
      {isBannerVisible ? (
        <Banner
          l10n={l10n}
          onAcceptAllClick={() => applyConsent(getAllOptions(true))}
          onRejectAllClick={() => applyConsent(getAllOptions(false))}
          onCustomizeClick={openOptionsModal}
          readMore={readMore}
        />
      ) : (
        undefined
      )}
      {isOptionsModalVisible ? (
        <OptionsModal
          consent={consent}
          l10n={l10n}
          onAcceptClick={() => applyConsent(consent)}
          onCloseClick={closeOptionsModal}
          onOptionChanged={onOptionChanged}
        />
      ) : (
        undefined
      )}
    </>
  )
}

export default SimpaConsentSolution
