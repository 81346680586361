import React from "react"
import { Link, useIntl } from "gatsby-plugin-intl"

interface Props {
  "aria-label"?: string
  children?: React.ReactNode
  className?: string
  target?: string
  title?: string
  to: string
}

const LinkTarget: React.FunctionComponent<Props> = ({ children, target, to, ...props }) => {
  const intl = useIntl()
  return target !== "_self" ? (
    <a {...props} href={`/${intl.locale}${to}`} target={target}>
      {children}
    </a>
  ) : (
    <Link {...props} to={to}>
      {children}
    </Link>
  )
}

LinkTarget.defaultProps = {
  target: "_self",
} as Partial<Props>

export default LinkTarget
