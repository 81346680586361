import React, { useMemo } from "react"
import { Link, useIntl } from "gatsby-plugin-intl"

import Favicon from "./Favicon"
import SimpaConsentSolution from "./simpa-consent-solution/SimpaConsentSolution"
import { listCookiesToWipe } from "../helpers/cookies"

declare const dataLayer: {
  push: Function
}

interface LayoutProps {
  children?: any
}

const Layout: React.FunctionComponent<LayoutProps> = ({ children }) => {
  const intl = useIntl()

  const onConsentChanged = (consent: SimpaConsentSolutionTypes.Consent) => {
    if (consent.marketing.hasBeenRevoked || consent.preferences.hasBeenRevoked || consent.statistics.hasBeenRevoked) {
      window.location.reload()
      return
    }

    if (consent.statistics.hasConsent) {
      if (process.env.NODE_ENV === "production") {
        dataLayer.push({ event: "has_statistics_cookie_consent" })
      }
    }

    if (consent.marketing.hasConsent) {
      if (process.env.NODE_ENV === "production") {
        dataLayer.push({ event: "has_marketing_cookie_consent" })
      }
    }
  }

  const cookies = useMemo(
    () => ({
      marketing: listCookiesToWipe("marketing"),
      statistics: listCookiesToWipe("statistics"),
    }),
    [listCookiesToWipe]
  )

  const l10n = useMemo<SimpaConsentSolutionTypes.L10N>(
    () => ({
      message: intl.formatMessage({ id: "cb_message" }),
      btnAcceptAll: `${intl.formatMessage({ id: "Accept All" })}`,
      btnRejectAll: `${intl.formatMessage({ id: "Reject All" })}`,
      btnCustomize: intl.formatMessage({ id: "Customize" }),
      modalTitle: intl.formatMessage({ id: "cb_modal_title" }),
      btnAccept: `${intl.formatMessage({ id: "Accept" })}`,
      btnCancel: intl.formatMessage({ id: "Cancel" }),
      marketingDesc: intl.formatMessage({ id: "cb_marketing_desc" }),
      marketingName: intl.formatMessage({ id: "cb_marketing_name" }),
      necessaryDesc: intl.formatMessage({ id: "cb_necessary_desc" }),
      necessaryName: intl.formatMessage({ id: "cb_necessary_name" }),
      preferencesDesc: intl.formatMessage({ id: "cb_preferences_desc" }),
      preferencesName: intl.formatMessage({ id: "cb_preferences_name" }),
      statisticsDesc: intl.formatMessage({ id: "cb_statistics_desc" }),
      statisticsName: intl.formatMessage({ id: "cb_statistics_name" }),
    }),
    [intl]
  )

  const readMore = <Link to="/cookie-policy/">{intl.formatMessage({ id: "Cookie Policy" })}.</Link>

  return (
    <>
      <Favicon />
      {children}
      <SimpaConsentSolution cookies={cookies} l10n={l10n} onConsentChanged={onConsentChanged} readMore={readMore} />
    </>
  )
}

export default Layout
